:root {
  --primary-yellow: #f7db24;
  --primary-blue: #54c6eb;
  --primary-green: #45cb85;
  --primary-white: #fff;
  --primary-dark: #6e6362;
  --text-light: #fff;
  --text-dark: var(--primary-dark);
  --link-color: var(--text-light);
  --font-family: Georgia, Times, serif;
  --font-weight: normal;
  --font-size-s: 12px;
  --font-size-m: 24px;
  --font-size-l: 36px;
  --menu-color: var(--text-light);
  --menu-background: #fff3;
  --menu-hover-color: var(--text-light);
  --menu-hover-background: #fff6;
}

html {
  color: var(--text-light);
  background-color: var(--primary-dark);
  font-size: 1em;
  line-height: 1.4;
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hidden, [hidden] {
  display: none !important;
}

.visually-hidden {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  white-space: nowrap;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visually-hidden.focusable:active, .visually-hidden.focusable:focus {
  clip: auto;
  height: auto;
  white-space: inherit;
  width: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

@media print {
  *, :before, :after {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: #fff !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}

body {
  scroll-behavior: smooth;
}

:root {
  font-size: var(--font-size-s);
}

@media (min-width: 675px) {
  :root {
    font-size: var(--font-size-m);
  }
}

@media (min-width: 1000px) {
  :root {
    font-size: var(--font-size-l);
  }
}

h1, h2, h3, h4, h5, h6 {
  font-size: 1.5rem;
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  color: var(--text-light);
}

a {
  color: var(--link-color);
  text-decoration: underline;
}

a:hover {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

p {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-style: normal;
}

section > .section_container {
  width: calc(100vw - 6rem);
  height: 100vh;
  margin: 0;
  padding: 1.5rem 3rem;
}

section.first > .section_container {
  background: var(--primary-yellow);
}

section.second > .section_container {
  background: var(--primary-blue);
}

section.third > .section_container {
  background: var(--primary-green);
}

section.fourth > .section_container {
  background: var(--primary-dark);
}

section > .section_container > .title > h1 {
  font-size: 4rem;
  text-decoration: underline;
}

section.light > .section_container > .title > h1 {
  color: var(--text-dark);
}

nav {
  z-index: 1;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
}

nav .nav-container {
  align-items: end;
  display: flex;
}

nav .menu-items {
  float: left;
  margin-right: .4em;
  font-size: 1.5rem;
}

nav .menu-items > .menu-items-container {
  flex-flow: column wrap;
  justify-content: right;
  align-items: end;
  display: flex;
}

nav .menu-items > .menu-items-container > a {
  float: left;
  color: var(--menu-color);
  background-color: var(--menu-background);
  margin: .25em 0;
  padding: .05em .2em;
  text-decoration: none;
}

nav .menu-items > .menu-items-container > a:last-child {
  margin-bottom: -.35em;
}

nav .menu-items > .menu-items-container > a:hover {
  color: var(--menu-hover-color);
  background-color: var(--menu-hover-background);
}

nav .menu-toggle {
  float: right;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

nav .menu-toggle > .line {
  width: 35px;
  background-color: var(--text-light);
  height: 7px;
  margin-top: 7px;
  transition: visibility 75ms ease-in, width .125s ease-in-out, transform .125s ease-in-out;
}

nav .menu-toggle.open > .line--1 {
  margin-top: 0;
}

nav .menu-toggle.open > .line--2 {
  visibility: hidden;
}

nav .menu-toggle.open > .line--1 {
  width: 45px;
  transform: translateY(14px)rotate(45deg);
}

nav .menu-toggle.open > .line--3 {
  width: 45px;
  transform: translateY(-14px)rotate(-45deg);
}

.hidden {
  visibility: hidden;
}

/*# sourceMappingURL=index.1d8a07d1.css.map */
